a {
    text-decoration: none;
}
.nav-link {
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-padding-x: 1rem;
}
// .btn:first-child:hover {
//     --bs-btn-hover-color: #ffffff;
//     --bs-btn-hover-bg: red;
// }

.nav-link.active.b-erp {
    border-top: 0;
    border-right: 0;
    border-left: 0;
}
.sr-only {
    display: none;
}
